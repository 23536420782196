import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Menu from '../components/Menu';
import { FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const EventDetails = () => {
    const limitsUser = JSON.parse(localStorage.getItem('limitsUser'));
    const { eventId } = useParams();

    const [eventDetails, setEventDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const getSingleEvent = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/SingleEventDetails/${eventId}`, {
                headers: {
                    Authorization: limitsUser?.token
                }
            });
            const data = response.data;
            if (data.status === 1) {
                setEventDetails(data.events);
            } else {
                console.error('Error: Event data not found');
            }
        } catch (error) {
            console.log('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (eventId) {
            getSingleEvent();
        }
    }, [eventId]);

    if (loading) {
        return <></>;
    }

    return (
        <>
            <div className='main-content'>
                <Header page={""} title={"Event Details"} backpage={'/'} />
                <div className='padding2'>
                    <div className='container'>
                        {/* You can uncomment the following line when the eventDetails.images[0] exists */}
                        <img src={`${process.env.REACT_APP_IMAGES_URL}/${eventDetails.images[0]}`} crossOrigin="anonymous" className="img-fluid w-100 rounded-4" alt="" />
                        <div className='mt-4'>
                            <h4 className='fw-bold mb-1'>{eventDetails.title}</h4>
                            <p>Type: Lorem Ispum</p>

                            <h6>
                                <FaRegCalendarAlt className='me-1' />
                                <span className='align-middle text-white'>
                                    {new Date(eventDetails.dates).toLocaleDateString()}
                                </span>
                                <FaRegClock className='me-1 ms-4' />
                                <span className='align-middle text-white'>
                                    {eventDetails.times}
                                </span>
                            </h6>

                            <p className='fw-semibold text-white mb-2 mt-4'>Rules/Description:</p>
                            <p>{eventDetails.rules}</p>

                            <div className='d-flex align-items-center participants-plus'>
                                <div className='d-flex me-2'>
                                    {
                                        eventDetails?.participants?.map((items, ind) => (
                                            <div key={ind} className='img1'>
                                                {items.user.profileImage !== "" ? (
                                                    <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.user.profileImage}`} crossOrigin="anonymous" className='img-fluid' alt="" />
                                                ) : (
                                                    <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                )}
                                            </div>
                                        ))
                                    }
                                </div>
                                <div>
                                    <p className='mb-0'>+{eventDetails?.participants?.length || 0} Participants</p>
                                </div>
                            </div>

                            <p className='fw-semibold text-white mb-2 mt-4'>Gallery</p>

                            <div className='row g-3 gallery-row'>
                                {
                                    eventDetails?.images?.map((item, ind) => (
                                        <div key={ind} className='col-md-4 col-4'>
                                            <img src={`${process.env.REACT_APP_IMAGES_URL}/${item}`} crossOrigin="anonymous" className='img-fluid w-100 rounded-3 img1' alt="" />
                                        </div>
                                    ))
                                }
                            </div>

                            <div className='mt-4'>
                                <button className='btn btn1 btn-orange w-100'>Join Event or View Results</button>
                            </div>
                        </div>
                    </div>
                </div>

                <Menu />
            </div>
        </>
    );
};

export default EventDetails;
