import React from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { Link } from 'react-router-dom'
import { BsSendFill } from 'react-icons/bs'
import { PiShareFat } from 'react-icons/pi'

const Voting = () => {
    const limitsUser = JSON.parse(localStorage.getItem('limitsUser'));

    return (
        <>
            <div className='main-content'>
                <Header page={""} title={"Art Battle Live Stream"} backpage={'/'} />

                <div className='padding2'>
                    <div className='container'>
                        <h4 className='fw-bold text-center'>“Theme for battle”</h4>

                        <div className='mb-3 voting'>
                            <nav className="nav nav1 d-flex scrollbar flex-nowrap pb-1">
                                <Link className="nav-link active" to="#">Vote</Link>
                                <Link className="nav-link" to="#">Battle</Link>
                            </nav>
                        </div>

                        <div className='card card1'>
                            <div className='card-body' style={{'height': '260px'}}>

                            </div>
                        </div>

                        <div className='card card1 bg-transparent border-0 mt-2'>
                            <div className='card-body'>
                                <div className='row gx-2 text-center align-items-center'>
                                    <div className='col-4'>
                                        <div className='player1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" style={{'width': '29px', 'minWidth': '29px', 'height': '29px'}} />
                                            <h6 className='mb-0 text-white fs-14'>John Doe</h6>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <h2 className='mb-1 text-white fw-semibold' style={{'fontSize': '36px'}}>VS</h2>
                                    </div>
                                    <div className='col-4'>
                                        <div className='player1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" style={{'width': '29px', 'minWidth': '29px', 'height': '29px'}} />
                                            <h6 className='mb-0 text-white fs-14'>Robi Z.</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex mt-2'>
                            <Link className='btn btn1 btn-orange w-100 me-2'>CAST YOUR VOTE</Link>
                            <Link className='bg-transparent btn btn-orange btn1 px-3' style={{'minWidth': 'auto', 'color':'#FF7933'}}><PiShareFat /></Link>
                        </div>
                    </div>
                </div>

                <div className='padding2 pt-0'>
                    <div className='container'>

                        <div className='card card1'>
                            <div className='card-body'>
                                <h5 className='fs-6 text-white fw-semibold text-center mb-4'>Live engagement</h5>

                                <div className='form form1'>
                                    <div className='comments-input'>
                                        <input type="text" className='form-control' placeholder="Say Something..." />
                                        <div className='icon1' style={{'background': '#FF7933'}}>
                                            <BsSendFill />
                                        </div>
                                    </div>
                                </div>

                                <div className='participants-box d-flex mt-4 mb-0'>
                                    <div className='img' style={{'width': '32px', 'minWidth': '32px', 'height': '32px'}}>
                                        <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                    </div>
                                    <div className='txt align-self-center'>
                                        <p className='mb-0 fw-medium fs-12 text-white'>Jakob Septimus Lorem ipsum odor amet, consectetuer adipiscing elit. </p>
                                    </div>
                                </div>

                                <div className='participants-box d-flex mt-4 mb-0'>
                                    <div className='img' style={{'width': '32px', 'minWidth': '32px', 'height': '32px'}}>
                                        <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                    </div>
                                    <div className='txt align-self-center'>
                                        <p className='mb-0 fw-medium fs-12 text-white'>Jakob Septimus Lorem ipsum odor amet, consectetuer adipiscing elit. </p>
                                    </div>
                                </div>

                                <div className='participants-box d-flex mt-4 mb-0'>
                                    <div className='img' style={{'width': '32px', 'minWidth': '32px', 'height': '32px'}}>
                                        <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                    </div>
                                    <div className='txt align-self-center'>
                                        <p className='mb-0 fw-medium fs-12 text-white'>Jakob Septimus Lorem ipsum odor amet, consectetuer adipiscing elit. </p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default Voting
