import React, { useState } from 'react';

const options1 = ['🍎', '🍌', '🍇', '🍒', '🍓', '🍍'];
const options2 = ['⚽️', '🏀', '🏈', '🎾', '🏐', '🏉'];

const getRandomIndex = (options) => Math.floor(Math.random() * options.length);

const Test = () => {
  const [roulette1Index, setRoulette1Index] = useState(0);
  const [roulette2Index, setRoulette2Index] = useState(0);
  const [isRolling, setIsRolling] = useState(false);

  const startRoulette = () => {
    setIsRolling(true);

    // Simulate random results after rolling
    setTimeout(() => {
      const randomIndex1 = getRandomIndex(options1);
      const randomIndex2 = getRandomIndex(options2);
      setRoulette1Index(randomIndex1);
      setRoulette2Index(randomIndex2);

      // Stop rolling after setting random values
      setIsRolling(false);
    }, 3000); // 3 seconds to stop rolling
  };

  return (
    <div>
      <div className="roulette-container">
        {/* Roulette 1 */}
        <div className={`roulette ${isRolling ? 'rolling' : ''}`}>
          {options1.map((option, index) => (
            <div key={index} className={`roulette-item ${index === roulette1Index ? 'active' : ''}`}>
              {option}
            </div>
          ))}
        </div>

        {/* Roulette 2 */}
        <div className={`roulette ${isRolling ? 'rolling' : ''}`}>
          {options2.map((option, index) => (
            <div key={index} className={`roulette-item ${index === roulette2Index ? 'active' : ''}`}>
              {option}
            </div>
          ))}
        </div>
      </div>

      {/* Start Button */}
      <button onClick={startRoulette} disabled={isRolling}>
        {isRolling ? 'Rolling...' : 'Start Roulette'}
      </button>

      {/* Show Random Values */}
      {!isRolling && (
        <div>
          <p>Roulette 1 Result: {options1[roulette1Index]}</p>
          <p>Roulette 2 Result: {options2[roulette2Index]}</p>
        </div>
      )}
    </div>
  );
};

export default Test;
