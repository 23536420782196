import React from 'react'
import { Link } from 'react-router-dom'

const Limits = () => {
    return (
        <>
            <div className="section1 position-relative">
                <div className="overlay padding text-center h-100 d-flex flex-column justify-content-center">
                    <div className="container">
                        <div className="login-box mx-auto">
                            <img src={require('../assets/images/logo.png')} alt="" className="img-fluid mb-4" style={{ "width": "228px" }} />
                            <h4 className='mb-3 fw-semibold'>CREATIVE GAMES</h4>
                            <p className='text-white fs-6 mb-0 fw-medium'>A battle between creators across genres.</p>

                            <div className='mt-5'>
                                <Link to={'/user-type'} className='btn btn1 btn-orange w-100' style={{'color': '#27005A'}}>Create an Account</Link>
                            </div>
                            <div className='mt-4'>
                                <Link to={'/signin'} className='btn btn1 w-100'>Login</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Limits
