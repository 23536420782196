import React, { createContext, useContext, useReducer } from 'react'
import reducer from './AllReducer';
import axios from 'axios';

const AllContext = createContext()

const initialState = {
    upcomingEvent: [],
    pastEvent: []
}

const AllContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const getUpcomingEvent = async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/GetUpcomingEvent`, {
                headers: {
                    Authorization: token
                }
            })
            const data = await response.data
            
            dispatch({ type: "setUpcomingEvent", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }
    }

    const getPastEvent = async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/GetPastEvent`, {
                headers: {
                    Authorization: token
                }
            })
            const data = await response.data
            
            dispatch({ type: "setPastEvent", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }
    }

    
    return <AllContext.Provider value={{...state, 
        getUpcomingEvent,
        getPastEvent
    }}>
        {children}
    </AllContext.Provider>
}

const useAllContext = () => {
    return useContext(AllContext)
}

export { AllContextProvider, useAllContext }