import React from 'react'
import Header from '../components/Header'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { Link } from 'react-router-dom';
import { AiOutlineMessage } from 'react-icons/ai';
import { FaRegClock } from 'react-icons/fa';
import Menu from '../components/Menu';

const EventHistory = () => {
    return (
        <>
            <div className='main-content'>
                <Header page={""} title={"Event History"} backpage={'/'} />

                <div className='swiper-overflow overflow-hidden'>
                    <div className='padding2 event-history'>
                        <div className='container'>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='w-100 me-2'>
                                    <p className='mb-0 fw-semibold text-white'>Swipe Through Limits Events</p>
                                </div>
                                <div className='text-end'>
                                    <h6 className='mb-0 text-nowrap'><Link to='' className='link'>See all</Link></h6>
                                </div>
                            </div>

                            <div className='swiper1 m-0'>
                                <Swiper
                                    slidesPerView={1.5}
                                    spaceBetween={8}
                                    centeredSlides={true}
                                    loop={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{
                                    575: {
                                        slidesPerView: 1.75
                                    },
                                    768: {
                                        slidesPerView: 1.75
                                    },
                                    1200: {
                                        slidesPerView: 1.75
                                    }
                                    }}
                                    className="mySwiper overflow-visible"
                                >
                                    <SwiperSlide>
                                        <div className='slider-box'>
                                            <img src={require('../assets/images/img_1.jpg')} className='img-fluid img1' alt="" />
                                            <div className='txt d-flex flex-column justify-content-end'>
                                                <div className=''>
                                                    <span className='badge1 bg-white text-black border-0 fs-10 fw-medium mb-1'>LATEST EVENT</span>
                                                    <h4 className='txt1 fw-semibold text-white mb-1'>Lorem ipsum odor amet, consectetuer adipiscing elit. Felis sodales bibendum.</h4>
                                                    <h6 className='date text-fe mb-0'>March 27, 2024</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='slider-box'>
                                            <img src={require('../assets/images/img_2.jpg')} className='img-fluid img1' alt="" />
                                            <div className='txt d-flex flex-column justify-content-end'>
                                                <div className=''>
                                                    <span className='badge1 bg-white text-black border-0 fs-10 fw-medium mb-1'>LATEST EVENT</span>
                                                    <h4 className='txt1 fw-semibold text-white mb-1'>Lorem ipsum odor amet, consectetuer adipiscing elit. Felis sodales bibendum.</h4>
                                                    <h6 className='date text-fe mb-0'>March 27, 2024</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='slider-box'>
                                            <img src={require('../assets/images/img_3.jpg')} className='img-fluid img1' alt="" />
                                            <div className='txt d-flex flex-column justify-content-end'>
                                                <div className=''>
                                                    <span className='badge1 bg-white text-black border-0 fs-10 fw-medium mb-1'>LATEST EVENT</span>
                                                    <h4 className='txt1 fw-semibold text-white mb-1'>Lorem ipsum odor amet, consectetuer adipiscing elit. Felis sodales bibendum.</h4>
                                                    <h6 className='date text-fe mb-0'>March 27, 2024</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='slider-box'>
                                            <img src={require('../assets/images/img_4.jpg')} className='img-fluid img1' alt="" />
                                            <div className='txt d-flex flex-column justify-content-end'>
                                                <div className=''>
                                                    <span className='badge1 bg-white text-black border-0 fs-10 fw-medium mb-1'>LATEST EVENT</span>
                                                    <h4 className='txt1 fw-semibold text-white mb-1'>Lorem ipsum odor amet, consectetuer adipiscing elit. Felis sodales bibendum.</h4>
                                                    <h6 className='date text-fe mb-0'>March 27, 2024</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='padding2 pt-0'>
                    <div className='container'>
                        <div className='d-flex align-items-center mb-2'>
                            <div className='w-100 me-2'>
                                <p className='mb-0 fw-semibold text-white'>Art Battles</p>
                            </div>
                            <div className='text-end'>
                                <h6 className='mb-0 text-nowrap'><Link to='' className='link'>See all</Link></h6>
                            </div>
                        </div>
                        
                        <div className='battle-box d-flex'>
                            <div className='img'>
                                <img src={require('../assets/images/img_1.jpg')} className='img-fluid img1' alt="" />
                            </div>
                            <div className='txt align-self-center'>
                                <p className='mb-3 text-white'>Tokyo Shibuya Bridge Event</p>
                                <h6 className='mb-0 fs-12'><span className='me-3'>Japan</span> <AiOutlineMessage className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom me-3'>28</span> <FaRegClock className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom'>12h</span></h6>
                            </div>
                        </div>
                        
                        <div className='battle-box d-flex'>
                            <div className='img'>
                                <img src={require('../assets/images/img_2.jpg')} className='img-fluid img1' alt="" />
                            </div>
                            <div className='txt align-self-center'>
                                <p className='mb-3 text-white'>Tokyo Shibuya Bridge Event</p>
                                <h6 className='mb-0 fs-12'><span className='me-3'>Japan</span> <AiOutlineMessage className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom me-3'>28</span> <FaRegClock className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom'>12h</span></h6>
                            </div>
                        </div>
                        
                        <div className='battle-box d-flex'>
                            <div className='img'>
                                <img src={require('../assets/images/img_3.jpg')} className='img-fluid img1' alt="" />
                            </div>
                            <div className='txt align-self-center'>
                                <p className='mb-3 text-white'>Tokyo Shibuya Bridge Event</p>
                                <h6 className='mb-0 fs-12'><span className='me-3'>Japan</span> <AiOutlineMessage className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom me-3'>28</span> <FaRegClock className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom'>12h</span></h6>
                            </div>
                        </div>
                        
                        <div className='battle-box d-flex'>
                            <div className='img'>
                                <img src={require('../assets/images/img_4.jpg')} className='img-fluid img1' alt="" />
                            </div>
                            <div className='txt align-self-center'>
                                <p className='mb-3 text-white'>Tokyo Shibuya Bridge Event</p>
                                <h6 className='mb-0 fs-12'><span className='me-3'>Japan</span> <AiOutlineMessage className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom me-3'>28</span> <FaRegClock className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom'>12h</span></h6>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <Menu />
            </div>
        </>
    )
}

export default EventHistory
