import React from 'react'

const ForgotPassword = () => {
    return (
        <>
            <div className='main-content'>
                <div className='padding login-section'>
                    <div className='container'>
                        <div className='login-box mx-auto'>
                            <h4 className='text-center mb-1'>Confirm your email</h4>
                            <p className='text-center mb-5'>Enter the email associated with your account and we’ll send an email with code to reset your password</p>
                            
                            <form action="" className='form form1'>
                                <div className='mb-3'>
                                    <input type="text" placeholder='Enter your email' className='form-control'/>
                                </div>

                                <div className='mt-3'>
                                    <button className='btn btn1 w-100 mt-4'>Send Code</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
