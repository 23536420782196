import React, { useEffect } from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import Menu from '../components/Menu';
import { useAllContext } from '../context/AllContext';

const Home = () => {
    const limitsUser = JSON.parse(localStorage.getItem('limitsUser'))

    const { getUpcomingEvent, upcomingEvent, getPastEvent, pastEvent } = useAllContext()

    useEffect(() => {
        getUpcomingEvent(limitsUser.token)
        getPastEvent(limitsUser.token)
    }, [])
    

    return (
        <>
            <div className='main-content'>
                <Header page={"home"} title={"Welcome back, Don!"} backpage={'/'} />

                <div className='swiper-overflow overflow-hidden'>
                    <div className='padding2 pb-1'>
                        <div className='container'>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='w-100 me-2'>
                                    <p className='mb-0 fw-semibold text-white'>Swipe Through Limits Events</p>
                                </div>
                                <div className='text-end'>
                                    <h6 className='mb-0 text-nowrap'><Link to='' className='link'>See all</Link></h6>
                                </div>
                            </div>

                            <div className='swiper1'>
                                <Swiper
                                    slidesPerView={1.5}
                                    spaceBetween={0}
                                    centeredSlides={true}
                                    loop={false}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{
                                        575: {
                                            slidesPerView: 1.5
                                        },
                                        768: {
                                            slidesPerView: 1.75
                                        },
                                        1200: {
                                            slidesPerView: 1.75
                                        }
                                    }}
                                    className="mySwiper overflow-visible"
                                >

                                    {
                                        upcomingEvent && upcomingEvent.map((items, ind) => {
                                            return <SwiperSlide key={ind}>
                                                <div className='slider-box'>
                                                    <Link to={`/event-details/${items._id}`}><img src={`${process.env.REACT_APP_IMAGES_URL}/${items.images[0]}`} crossOrigin="anonymous" className="img-fluid img1" alt="" /></Link>
                                                </div>
                                            </SwiperSlide>
                                        })
                                    }
                                    {/* <SwiperSlide>
                                        <div className='slider-box'>
                                            <Link to={"/event-details/1"}><img src={require('../assets/images/img_1.jpg')} className='img-fluid img1' alt="" /></Link>
                                        </div>
                                    </SwiperSlide> */}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='padding2 pt-0'>
                    <div className='container'>
                        <div className='d-flex align-items-center mb-3'>
                            <div className='w-100 me-2'>
                                <p className='mb-0 fw-semibold text-white'>See Previous Event Winners</p>
                            </div>
                            <div className='text-end'>
                                <h6 className='mb-0 text-nowrap'><Link to='' className='link'>See all</Link></h6>
                            </div>
                        </div>

                        <div className='mb-3'>
                            <nav className="nav nav1 d-flex scrollbar flex-nowrap pb-1">
                                <Link className="nav-link active" to="#">Tokyo</Link>
                                <Link className="nav-link" to="#">Taiwan</Link>
                                <Link className="nav-link" to="#">Hong Kong</Link>
                                <Link className="nav-link" to="#">Seoul</Link>
                                <Link className="nav-link" to="#">Singapore</Link>
                            </nav>
                        </div>
                        
                        <div className='row gy-3 gx-3'>
                            
                            {
                                pastEvent?.map((items, ind) => {
                                    return <div className='col-xl-4 col-md-6 col-12' key={items._id}>
                                        <Link to={`/match-details/${items._id}`}><div className='card card1'>
                                            <div className='card-body'>
                                                <h6 className='mb-1 text-white text-center'>{items.title}</h6>
                                                <p className='fs-12 text-center mb-2'>{new Date(items.dates).toLocaleDateString()}</p>
                                        
                                                <div className='row gx-2 text-center align-items-center'>
                                                    <div className='col-4'>
                                                        <div className='player1'>
                                                            {items.participants[0].user.profileImage !== "" ? (
                                                                <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.participants[0].user.profileImage}`} crossOrigin="anonymous" className='img-fluid img1' alt="" />
                                                            ) : (
                                                                <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                                            )}
                                                            
                                                            <h6 className='mb-0 text-white'>{items.participants[0].user.username}</h6>
                                                            <p className='fs-12 mb-0 text-uppercase'>USA</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <h2 className='mb-1 text-white fw-semibold'>1st : 2nd</h2>
                                                        <span className='fs-12 badge1'>90+4</span>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='player1'>
                                                            {items.participants[1].user.profileImage !== "" ? (
                                                                <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.participants[1].user.profileImage}`} crossOrigin="anonymous" className='img-fluid img1' alt="" />
                                                            ) : (
                                                                <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                                            )}
                                                            <h6 className='mb-0 text-white'>{items.participants[1].user.username}</h6>
                                                            <p className='fs-12 mb-0 text-uppercase'>JAPAN</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></Link>
                                    </div>
                                })
                            }

                            {/* <div className='col-xl-4 col-md-6 col-12'>
                                <Link to="/match-details"><div className='card card1'>
                                    <div className='card-body'>
                                        <h6 className='mb-1 text-white text-center'>Tokyo Event Final Results</h6>
                                        <p className='fs-12 text-center mb-2'>June 9, 2024</p>
                                
                                        <div className='row gx-2 text-center align-items-center'>
                                            <div className='col-4'>
                                                <div className='player1'>
                                                    <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                                    <h6 className='mb-0 text-white'>Player 1</h6>
                                                    <p className='fs-12 mb-0 text-uppercase'>USA</p>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <h2 className='mb-1 text-white'>1 : 1</h2>
                                                <span className='fs-12 badge1'>90+4</span>
                                            </div>
                                            <div className='col-4'>
                                                <div className='player1'>
                                                    <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                                    <h6 className='mb-0 text-white'>Player 2</h6>
                                                    <p className='fs-12 mb-0 text-uppercase'>JAPAN</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></Link>
                            </div> */}

                        </div>
                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default Home
