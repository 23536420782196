
const reducer = (state, action) => {
    switch (action.type) {
        case "setUpcomingEvent":
            return {
                ...state,
                upcomingEvent: action.payload.events
            }
        case "setPastEvent":
            return {
                ...state,
                pastEvent: action.payload.events
            }
          
            
    
        default:
            return state;
    }
    
}

export default reducer