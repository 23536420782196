import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'

const UserType = () => {

    const [isLoading, setIsLoading] = useState(true)

    const loadingFun = () => {
        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
    }

    useEffect(() => {
        loadingFun()
    }, [])

    return (
        <>
            {/* { isLoading && <Loader /> } */}

            <div className='main-content'>
                <div className='padding login-section'>
                    <div className='container'>
                        <div className='login-box mx-auto'>
                            <h4 className='text-center mb-1'>User Type</h4>
                            <p className='text-center mb-5'>Please Select User Type.</p>

                            <div className='d-flex flex-wrap flex-sm-nowrap justify-content-center'>
                                <Link to="/signup/fan"><div className='usertype-box text-center'>
                                    <img src={require('../assets/images/user.png')} className='img-fluid img1' alt="" />
                                    <h5 className='text-83'>LIMITS Fan</h5>
                                </div></Link>

                                <Link to="/signup/artist"><div className='usertype-box text-center'>
                                    <img src={require('../assets/images/artist.png')} className='img-fluid img1' alt="" />
                                    <h5 className='text-83'>Artist</h5>
                                </div></Link>
                                
                                {/* <Link to="/signup/business"><div className='usertype-box text-center'>
                                    <img src={require('../assets/images/Global-Business.png')} className='img-fluid img1' alt="" />
                                    <h5 className='text-83'>Business</h5>
                                </div></Link> */}
                            </div>

                            <p className='mb-0 text-center mt-5'>Already hev an account? <span className='text-white'><Link to="/signin" className='link'>Login</Link></span></p>

                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserType
