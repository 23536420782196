import React from 'react'
import { BiSearch } from 'react-icons/bi'
import { MdArrowBackIos, MdLogout } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'

const Header = ({ page, title, backpage }) => {

    const navigate = useNavigate()

    const logoutFun = () => {
        localStorage.removeItem('limitsUser')
        navigate('/signin')
    }

    return (
        <>
            <div className='header-top'>
                <div className='container'>
                    <div className='d-flex align-items-center'>
                        {
                            (page !== "home") && <div className='me-2 w-50'>
                                <Link to={backpage}><MdArrowBackIos /></Link>
                            </div>
                        }
                        <div className={`me-2 w-100 ${(page !== "home") && "text-center"}`}>
                            <p className='mb-0 fw-semibold text-white'>{title}</p>
                        </div>
                        <div className='w-50 d-flex justify-content-end align-items-center'>
                            {
                                (page !== "profile") && (page !== "profile2") && <><div className='me-2'>
                                    <BiSearch className='fs-4' />
                                </div>
                                <div className="dropdown dropdown1">
                                    <button className="btn btn-primary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={require('../assets/images/user-img.png')} className='img-fluid user1' alt="" />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                                        <li><Link className="dropdown-item" to="/settings">Setting</Link></li>
                                        <li><Link className="dropdown-item" to="/signin" onClick={logoutFun}>Logout</Link></li>
                                    </ul>
                                </div></>
                            }
                            {
                                (page == "profile2") && <div className='me-2'>
                                    <Link to="/signin" className='text-main' onClick={logoutFun}><MdLogout className='fs-4' /></Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Header
