import React from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { Link } from 'react-router-dom'
import { FaInstagram, FaLink } from 'react-icons/fa'

const ProfileDetails = () => {
    return (
        <>
            <div className='main-content'>
                <Header page={"profile"} title={"My Profile"} backpage={'/profile'} />

                <div className='padding2'>
                    <div className='container'>
                        <div className='my-profile mb-5'>
                            <img src={require('../assets/images/profile.png')} className='img-fluid img1 d-block mx-auto' alt="" />
                        
                            <div className='text-center mt-4'>
                                <h5 className='mb-1'>Jess Bailey</h5>
                                <h6 className='fs-12'>Profile bio information will be here!</h6>
                                
                                <div className='mt-3 mb-4 d-flex justify-content-center align-items-center'>
                                    <div className='w-100'>
                                        <h6 className='fw-semibold text-white mb-1'>Japan</h6>
                                        <h6 className='mb-0 fs-12'>Country</h6>
                                    </div>
                                    <div className='w-100'>
                                        <h6 className='fw-semibold text-white mb-1'>29</h6>
                                        <h6 className='mb-0 fs-12'>Past Voting</h6>
                                    </div>
                                    <div className='w-100'>
                                        <h6 className='fw-semibold text-white mb-1'>43</h6>
                                        <h6 className='mb-0 fs-12'>Participated Events</h6>
                                    </div>
                                </div>

                                <h6 className='fs-12 mb-2 text-nowrap ellipsis1'><FaLink className='me-1 align-middle' /><span className='align-middle'>http//www.jessbailey.com/38/profile/q9nxdhfhfhjk</span></h6>
                                <h6 className='fs-12 mb-2 text-nowrap ellipsis1'><FaInstagram className='me-1 align-middle' /><span className='align-middle'>http//www.intagram.com/jessbailey922</span></h6>
                            </div>
                        </div>
                        

                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default ProfileDetails
