import React from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { GoDotFill } from 'react-icons/go'
import { FaRegHeart, FaTelegram, FaTelegramPlane } from 'react-icons/fa'
import { BiMessageAltDots } from 'react-icons/bi'

const LiveEvent = () => {
    return (
        <>
            <div className='main-content'>
                <Header page={""} title={"Art Battle Results"} backpage={'/match-details'} />

                <div className='padding2'>
                    <div className='container'>
                        <div className='card card1 overflow-hidden'>
                            <div className='card-body p-0'>
                                <div className='live-box position-relative'>
                                    <span className='live-tag'><GoDotFill /> Live now</span>
                                    <video autoPlay muted controls tabIndex="0" playsInline className='w-100 h-100 video1'>
                                        <source src={require("../assets/videos/video.mp4")} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='padding2 pt-0'>
                    <div className='container'>
                        <div className='d-flex align-items-center mb-3'>
                            <div className='w-100 me-2'>
                                <p className='mb-1 fw-semibold text-white'>Tokyo Shibuya Bridge Event | Live</p>
                                <h6 className='mb-0 text-nowrap'>Sep, 31, 2024</h6>
                            </div>
                        </div>
                        <div className='card card1'>
                            <div className='card-body'>
                                <p className='mb-4 fw-semibold text-white text-center'>Comments</p>

                                <div>
                                    <form action="" className='form form1'>
                                        <div className='comments-input'>
                                            <input type="text" className='form-control' placeholder="Say Something..." />
                                            <div className="icon1">
                                                <FaTelegramPlane />
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className='comments-box d-flex mt-4'>
                                    <div className='img'>
                                        <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                    </div>
                                    <div className='txt'>
                                        <p className='mb-2 fw-medium fs-12'><span className='text-white me-2'>Jakob Septimus</span> <span className='small'>30 min ago</span></p>
                                        <h6 className='mb-3 fs-12 text-fe'>Lorem ipsum odor amet, consectetuer adipiscing elit. Gravida magnis parturient placerat pharetra ullamcorper sollicitudin. Integer id interdum tellus odio facilisis maecenas.</h6>
                                        <h6 className='mb-0 fs-12'><FaRegHeart className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom'>2</span> <BiMessageAltDots className='align-bottom fs-6 text-white ms-3 me-1' /> <span className='align-bottom'>3</span></h6>
                                    </div>
                                </div>

                                <div className='comments-box d-flex mt-4'>
                                    <div className='img'>
                                        <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                    </div>
                                    <div className='txt'>
                                        <p className='mb-2 fw-medium fs-12'><span className='text-white me-2'>Jakob Septimus</span> <span className='small'>30 min ago</span></p>
                                        <h6 className='mb-3 fs-12 text-fe'>Lorem ipsum odor amet, consectetuer adipiscing elit. Gravida magnis parturient placerat pharetra ullamcorper sollicitudin. Integer id interdum tellus odio facilisis maecenas.</h6>
                                        <h6 className='mb-0 fs-12'><FaRegHeart className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom'>2</span> <BiMessageAltDots className='align-bottom fs-6 text-white ms-3 me-1' /> <span className='align-bottom'>3</span></h6>
                                    </div>
                                </div>

                                <div className='comments-box d-flex mt-4'>
                                    <div className='img'>
                                        <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                    </div>
                                    <div className='txt'>
                                        <p className='mb-2 fw-medium fs-12'><span className='text-white me-2'>Jakob Septimus</span> <span className='small'>30 min ago</span></p>
                                        <h6 className='mb-3 fs-12 text-fe'>Lorem ipsum odor amet, consectetuer adipiscing elit. Gravida magnis parturient placerat pharetra ullamcorper sollicitudin. Integer id interdum tellus odio facilisis maecenas.</h6>
                                        <h6 className='mb-0 fs-12'><FaRegHeart className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom'>2</span> <BiMessageAltDots className='align-bottom fs-6 text-white ms-3 me-1' /> <span className='align-bottom'>3</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default LiveEvent
