import axios from 'axios';
import React, { useState } from 'react'
import { FcGoogle } from 'react-icons/fc'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

const Signin = () => {

    const navigate = useNavigate()

    const [errors, setErrors] = useState({});
    const [inputValue, setInputValue] = useState({
        email: "",
        password: ""
    });

    const inputHandler = (e) => {
        const {name, value} = e.target;
        setInputValue({
            ...inputValue,
            [name]: value
        })
    }

    const validate = () => {
        let errors = {};
        const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (!inputValue.email) {
            errors.email = "Email is required"
        } else if (emailReg.test(inputValue.email) === false) {
            errors.email = "Invalid Email";
        }

        if (!inputValue.password) {
            errors.password = "Password is required"
        }
    
        return errors;
    };

    const signinForm = async (e) => {
        e.preventDefault()

        const formErrors = validate();
        setErrors(formErrors);

        const loginData = {
            email: inputValue.email,
            password: inputValue.password
        }
        
        
        if (Object.keys(formErrors).length === 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, loginData)
                const data = await response.data

                if(data.status === 0){
                    toast.error(data.message)
                }
                console.log(data.token);
                

                if(data.status === 1){
                    localStorage.setItem('limitsUser', JSON.stringify(data))
                    toast.success(data.message)
                    navigate('/')
                }

            } catch (error) {
                console.log('Error:', error.response.data.message);
                toast.error(error.response.data.message)
            }
        }
    }


    return (
        <>
            <div className='main-content'>
                <div className='padding login-section'>
                    <div className='container'>
                        <div className='login-box mx-auto'>
                            <h4 className='text-center mb-1'>Welcome Back, Don!</h4>
                            <p className='text-center mb-5'>Please enter your details below.</p>
                            
                            <form action="" className='form form1' onSubmit={signinForm}>
                                <div className='mb-3'>
                                    <input type="text" className="form-control" placeholder="Email" name="email" onChange={inputHandler} />
                                    {errors.email && <p className="error-text">{errors.email}</p> }
                                </div>

                                <div className='mb-3'>
                                    <input type="password" className="form-control" placeholder="Password" name="password" onChange={inputHandler} />
                                    {errors.password && <p className="error-text">{errors.password}</p> }
                                </div>

                                <div className='mb-3 mt-4 d-flex align-items-center'>
                                    <div className='w-50'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="formCheckDefault" />
                                            <label className="form-check-label" htmlFor="formCheckDefault">Remember me</label>
                                        </div>
                                    </div>
                                    <div className='w-50 text-end'>
                                        <h6 className='mb-0 text-white'><Link to="/forgot-password" className='link'>Forgot password?</Link></h6>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <button className='btn btn1 w-100 mt-4'>Login</button>
                                </div>
                            </form>

                            <div className='or position-relative text-center my-4 py-2'>
                                <p className='mb-0'>Or</p>
                            </div>

                            <div className='mt-3'>
                                <button type='submit' className='btn btn1 btn-border w-100'><FcGoogle className='align-middle fs-4 me-2' /> <span className='align-middle'>Continue with Google</span></button>
                            </div>
                            <p className='mb-0 text-center mt-3'>Don’t have an account? <span className='text-white'><Link to="/user-type" className='link'>Register</Link></span></p>

                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signin
