import React from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { Link } from 'react-router-dom'
import { BsSendFill } from 'react-icons/bs'
import { PiShareFat } from 'react-icons/pi'

const Roulette = () => {
    const limitsUser = JSON.parse(localStorage.getItem('limitsUser'));

    return (
        <>
            <div className='main-content'>
                <Header page={""} title={"Roulette"} backpage={'/'} />

                <div className='padding2'>
                    <div className='container'>
                        <h4 className='fw-bold text-center'>Lorem Ipsum Event Name</h4>

                        <div className='mt-4 text-center'>
                            <h5 className='border d-inline-block fs-16 mb-0 px-3 py-2'>Battle 4/20</h5>
                        </div>

                        <div className='card card1 mt-5'>
                            <div className='card-body' style={{'height': '200px'}}>

                            </div>
                        </div>

                        <div className='card card1 bg-transparent border-0 mt-2'>
                            <div className='card-body'>
                                <div className='row gx-2 text-center align-items-center'>
                                    <div className='col-4'>
                                        <div className='player1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" style={{'width': '29px', 'minWidth': '29px', 'height': '29px'}} />
                                            <h6 className='mb-0 text-white fs-14'>John Doe</h6>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <h2 className='mb-1 text-white fw-semibold' style={{'fontSize': '36px'}}>VS</h2>
                                    </div>
                                    <div className='col-4'>
                                        <div className='player1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" style={{'width': '29px', 'minWidth': '29px', 'height': '29px'}} />
                                            <h6 className='mb-0 text-white fs-14'>Robi Z.</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex mt-5'>
                            <Link className='btn btn1 btn-orange w-100' style={{'fontSize': "24px"}}>SPIN THE ROULETTE!</Link>
                        </div>
                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default Roulette
