import axios from 'axios'
import React, { useState } from 'react'
import { FcGoogle } from 'react-icons/fc'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const Signup = () => {

    const navigate = useNavigate()
    const params = useParams()

    const [errors, setErrors] = useState({});
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        termsCondition: false

    });

    console.log(inputValue);
    

    const inputHandler = (e) => {
        console.log(e);
        
        const {name, type, value, checked} = e.target;
        setInputValue({
            ...inputValue,
            [name]: type === 'checkbox' ? checked : value,
        })
    }

    const validate = () => {
        let errors = {};
        const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (!inputValue.email) {
            errors.email = "Email is required"
        } else if (emailReg.test(inputValue.email) === false) {
            errors.email = "Invalid Email";
        }

        if (!inputValue.password) {
            errors.password = "Password is required"
        }

        if (!inputValue.confirmPassword) {
            errors.confirmPassword = "Confirm Password is required"
        } else if (inputValue.password != inputValue.confirmPassword) {
            errors.confirmPassword = "Password & Confirm Password is not matched"
        }

        if (!inputValue.termsCondition) {
            errors.termsCondition = "Please accept the terms and condition"
        }
    
        return errors;
    };

    const signupForm = async (e) => {
        e.preventDefault()

        const formErrors = validate();
        setErrors(formErrors);

        const signupData = {
            email: inputValue.email,
            password: inputValue.password,
            type: params.userType
        }
        
        
        if (Object.keys(formErrors).length === 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/register`, signupData)
                const data = await response.data

                if(data.status === 0){
                    toast.error(data.message)
                }

                if(data.status === 1){
                    toast.success(data.message)
                    navigate('/signin')
                }

            } catch (error) {
                console.log('Error:', error.response.data.message);
                toast.error(error.response.data.message)
            }
        }
    }

    return (
        <>
            <div className='main-content'>
                <div className='padding login-section'>
                    <div className='container'>
                        <div className='login-box mx-auto'>
                            <h4 className='text-center mb-1'>Welcome to Limits!</h4>
                            <p className='text-center mb-5'>Register to join us by entering your details below.</p>
                            
                            <form action="" className='form form1' onSubmit={signupForm}>
                                <div className='mb-3'>
                                    <input type="text" className="form-control" placeholder="Email" name="email" onChange={inputHandler} />
                                    {errors.email && <p className="error-text">{errors.email}</p> }
                                </div>

                                <div className='mb-3'>
                                    <input type="password" className="form-control" placeholder="Password" name="password" onChange={inputHandler} />
                                    {errors.password && <p className="error-text">{errors.password}</p> }
                                </div>

                                <div className='mb-3'>
                                    <input type="password" className="form-control" placeholder="Confirm Password" name="confirmPassword" onChange={inputHandler} />
                                    {errors.confirmPassword && <p className="error-text">{errors.confirmPassword}</p> }
                                </div>

                                <div className='mb-3 mt-4'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="formCheckDefault" name="termsCondition" value="" onChange={inputHandler} />
                                        <label className="form-check-label" htmlFor="formCheckDefault">I agree to Terms and condition</label>
                                    </div>
                                    {errors.termsCondition && <p className="error-text">{errors.termsCondition}</p> }
                                </div>

                                <div className='mt-3'>
                                    <button type='submit' className='btn btn1 w-100 mt-4'>Register</button>
                                </div>
                            </form>

                            <div className='or position-relative text-center my-4 py-2'>
                                <p className='mb-0'>Or</p>
                            </div>

                            <div className='mt-3'>
                                <button className='btn btn1 btn-border w-100'><FcGoogle className='align-middle fs-4 me-2' /> <span className='align-middle'>Continue with Google</span></button>
                            </div>
                            <p className='mb-0 text-center mt-3'>Have an account? <span className='text-white'><Link to="/signin" className='link'>Login</Link></span></p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signup
